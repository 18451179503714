.contentOrderWrap {
  position: relative;
  height: 90%;
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f7f8f9;
  border-radius: 30px;
  -webkit-box-shadow: 0px 0px 33px 0px rgba(219, 221, 255, 0.46);
  -moz-box-shadow: 0px 0px 33px 0px rgba(219, 221, 255, 0.46);
  box-shadow: 0px 0px 33px 0px rgba(219, 225, 255, 0.46);
  padding: 20px;
  font-family: "Varela";
}

.leftSide {
  width: 100%;
  height: 100%;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.rightSide {
  width: 30%;
  height: 100%;
  border: 1px solid black;
  margin: 0 20px;
}

.orderNavWrap {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.orderNav {
  width: 100%;
}

.orderNav ul {
  list-style-type: none;
  padding: 0;
}

.orderNav ul li {
  display: inline-block;
  color: #D2CCD0;
  margin-right: 30px;
  font-weight: bold;
  font-size: 20px;
  border-bottom: 4px solid transparent;
  transition: color ease-in-out .2s, border-color ease-in-out .2s;
  cursor: pointer;
}

.orderNav ul li.active, .orderNav ul li:hover {
  color: #EF5F63;
  border-bottom: 4px solid #EF5F63;
}

.addOrderWrap {
  width: 100%;
  text-align: right;
  text-align: -webkit-right;
  text-align: -moz-right;
  text-align: -o-right;
  text-align: -ms-right;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.addOrderWrap input {
  display: block;
  width: 100%;
  height: 30px;
  border: 0;
  border-bottom: 2px solid #ddd;
  padding: 1.6em;
  box-sizing: border-box;
  background: none;
  outline: none;
  color: #78909c;
  transition: all 0.2s ease-in;
  font-size: 16px;
  margin-right: 20px;
}

.addOrder {
  padding: 10px 45px;
  border: 2px solid #EF5F63;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 25px;
}

.addOrder:hover {
  color: #fff;
  background: #EF5F63;
}

.addOrderText {
  font-weight: bold;
  font-size: 17px;
  padding-left: 5px;
}

.orderWrap {
  position: relative;
  width: 100%;
  max-height: inherit;
  overflow-y: auto;
}

.contentOrderWrap table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 20px;
}

.contentOrderWrap thead tr, .contentOrderWrap tbody tr {
  background-color: #F4F6F8;
  transition: background-color ease-in-out .2s;
}

.contentOrderWrap thead th {
  text-align: left;
  border-top: 3px solid #E2E0E3;
  border-bottom: 3px solid #E2E0E3;
}

.contentOrderWrap thead th, .contentOrderWrap tbody td {
  padding: 20px 20px 20px 0;
}

.contentOrderWrap thead th:first-child, .contentOrderWrap tbody td:first-child {
  padding: 20px 0 20px 20px;
}

.contentOrderWrap tr td:first-child, .contentOrderWrap tr th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 3px solid #E2E0E3;
}

.contentOrderWrap tr td:last-child, .contentOrderWrap tr th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 3px solid #E2E0E3;
}

.contentOrderWrap thead button {
  border: 0;
  border-radius: none;
  font-family: inherit;
  font-weight: 700;
  font-size: inherit;
  padding: 0.5em;
  margin-bottom: 1px;
}

.contentOrderWrap thead button.ascending::after {
  content: '👇';
  display: inline-block;
  margin-left: 1em;
}

.contentOrderWrap thead button.descending::after {
  content: '☝️';
  display: inline-block;
  margin-left: 1em;
}

.contentOrderWrap tbody td {
  padding: 0.5em;
  border-top: 3px solid #E2E0E3;
  border-bottom: 3px solid #E2E0E3;
}

.contentOrderWrap tbody tr:hover {
  background-color: #fff;
}

.Closed {
  color: #F5AC21;
}

.Open {
  color: #20cd31;
}

.Shipped {
  color: #EE3D45;
}

/* POPUP */
.addNewOrderWrap {
  width: 100%;
  height: 100%;
  max-height: 700px;
  overflow-y: auto;
  position: relative;
}

.addNewOrderForm .input-group {
  margin-bottom: 20px;
}

.productDetailsInput, .orderDetailsInput {
  display: block;
  width: 100%;
  height: 30px;
  border: 0;
  border-bottom: 2px solid #ddd;
  padding: 1.6em;
  box-sizing: border-box;
  background: none;
  outline: none;
  color: #78909c;
  transition: all 0.2s ease-in;
  font-size: 16px;
}

.orderDetailsInputHalf {
  display: inline-block;
  width: 50%;
}

.productDetailsInput::placeholder, .orderDetailsInput::placeholder {
  position: absolute;
  top: 35%;
  left: 15px;
  color: #B0BEC5;
  pointer-events: none;
  font-size: 16px;
  transition: all 0.3s ease;
}

.orderDetailsSelect {
  display: block;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #ddd;
  padding: 1.6em;
  box-sizing: border-box;
  background: none;
  outline: none;
  color: #78909c;
  transition: all 0.2s ease-in;
  font-size: 16px;
}

.productSummary {
  width: 100%;
  display: flex;
}

.productSummaryLeft {
  width: 50%;
  text-align: left;
  justify-content: left;
}

.addNewLine {
  color: #EF5F63;
  font-weight: bold;
  cursor: pointer;
}

.productSummaryRight {
  width: 50%;
  text-align: right;
  justify-content: right;
}

.submitNewOrder {
  margin-top: 20px;
}

.submitNewOrderBtn {
  width: 100%;
  padding: 10px 45px;
  border: 2px solid #EF5F63;
  font-weight: 500;
  background: #EF5F63;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
}

.removeProduct {
  color: #EF5F63;
  cursor: pointer;
}

/* scroll */
.addNewOrderWrap::-webkit-scrollbar-track, .orderWrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.addNewOrderWrap::-webkit-scrollbar, .orderWrap::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
  transition: background-color ease-in-out .2s;
}

.addNewOrderWrap::-webkit-scrollbar-thumb, .orderWrap::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #E2E0E3;
}

.addNewOrderWrap::-webkit-scrollbar-thumb:hover, .orderWrap::-webkit-scrollbar-thumb:hover {
  background-color: #C8C8C8;
}

/* orderSwitch */
.newUserSwitch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.newUserSwitch .switch {
  position: relative;
  width: 150px;
  height: 50px;
  text-align: center;
  background: #4CD964;
  transition: all 0.2s ease;
  border-radius: 25px;
  margin-left: 30px;
}

.newUserSwitch .switch span {
  position: absolute;
  width: 20px;
  height: 4px;
  top: 50%;
  left: 50%;
  margin: -2px 0px 0px -4px;
  background: white;
  display: block;
  transform: rotate(-45deg);
  transition: all .2s ease;
  border-radius: 2px;
}

.newUserSwitch .switch span:after {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 12px;
  margin-top: -8px;
  background: white;
  transition: all .2s ease;
  border-radius: 2px;
}

.newUserSwitch input[type=radio] {
  display: none;
}

.switchLabel {
  position: absolute;
  cursor: pointer !important;
  pointer-events: all !important;
  ;
  color: rgba(0, 0, 0, 0.2);
  width: 60px;
  line-height: 50px;
  height: 100%;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.newUserSwitch .switch label[for=yes] {
  left: 0px !important;
  top: 0;
}

.newUserSwitch .switch label[for=no] {
  right: 0px;
  top: 0;
  left: auto !important;
}

#no:checked~.switch {
  background: #EF5F63;
}

#no:checked~.switch span {
  background: white;
  margin-left: -8px;
}

#no:checked~.switch span:after {
  background: white;
  height: 20px;
  margin-top: -8px;
  margin-left: 8px;
}

#yes:checked~.switch label[for=yes] {
  color: white;
}

#no:checked~.switch label[for=no] {
  color: white;
}

.autoCompleteWrap {
  width: 100%;
  text-align: center;
  position: relative;
}

#autoCompleteInput {
  width: 50%;
  height: 30px;
  border: 0;
  border-bottom: 2px solid #EF5F63;
  padding: 1.6em;
  box-sizing: border-box;
  background: none;
  outline: none;
  color: #78909c;
  transition: all 0.2s ease-in;
  font-size: 16px;
  text-align: center;
}

.autoCompleteContainer {
  position: absolute;
  width: 50%;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.autoCompleteOption {
  width: 100%;
  background-color: white;
  padding: 5px 10px;
  margin: 2px 0;
  border-bottom: 2px solid #EFEFEF;
  border-radius: 5px;
  transition: background-color .2s ease-in-out;
  cursor: pointer;
  z-index: 10;
}

.autoCompleteOption:hover {
  background-color: #EFEFEF;
}

/* responsive */
@media screen and (max-width: 1024px) {
  .addOrder {
    padding: 10px 25px;

  }
}