
* {
  box-sizing: border-box;
    font-family: "Varela";
}

body, #root {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  width: 100vw;
  height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

.bodyWrap {
  width: calc(100% - 290px);
  height: calc(100% - 100px);
  left: 250px;
  top: 80px;
  background-color: #F2F4FA;
  border-radius: 50px;
}

.maincolor {
  color: #EF5F63;
}

.bold {
  font-weight: bold;
}

.disabledEvent {
  pointer-events: none;
}

.clickable {
  cursor: pointer;
}

/* responsive  */

@media screen and (max-width: 1024px) {
  body, #root {
    height: auto;
    width: auto;
  }
  .bodyWrap {
    width: 100%;
    left: 0;
    position: relative !important;
    justify-content: unset !important;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;