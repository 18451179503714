.adminPanelWrap {
  height: 95%;
  width: 95%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.adminPanelHeader {
    height: 10%;
    width: 100%;
}
.adminNav {
  width: 100%;
}
.adminNavList {
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #F4F6F8;
  border: 2px solid #E2E0E3;
  border-radius: 10px;
  padding: 15px 5px;
}
.adminNavItem {
  list-style-type: none;
  margin: 0 15px;
  padding: 5px 10px;
  font-size: 16px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out;
  cursor: pointer;
  border-radius: 10px;
}
.adminNavItem:hover {
  color: #EF5F63;
}
.adminNavItem.active-setting {
  color: #fff;
  background-color: #EF5F63;

}


/* responsive */

@media screen and (max-width: 1024px) {
  .adminPanelWrap {
    overflow: unset;
    overflow-x: hidden;
  }
  .adminPanelHeader {
    text-align: center;
  }
  .addNewUserWrap {
    justify-content: center !important;
  }
  .usersColumn {
    flex-direction: column !important;
  }
  .usersInfo {
    width: 100% !important;
    margin: 0 !important;
  }
}