.bodyWrap.dashboardPage {
  flex-direction: column;
}

.topPanelWrap {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-bottom: 30px;
}

.topPanelData {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.topPanelDataBox {
  padding: 30px 20px;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  background-color: rgba(135, 131, 215, 0.2);
  border-radius: 15px;
  height: 100%;
  width: 25%;
}

.topPanelDataBox:first-child {
  background-color: rgba(135, 131, 215, 0.2);
}

.topPanelDataBox:nth-child(2) {
  background-color: rgba(239, 95, 99, 0.2)
}

.topPanelDataBox:last-child {
  background-color: rgba(239, 95, 99, 0.2);
}

.topPanelDataIcon svg {
  font-size: 35px;
  margin-right: 15px;
  color: #EF5F63;
}

.topPanelDataRangeBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 15px;
  height: 100%;
  width: 50%;
}
.topPanelDataRangeBox .topPanelDataIcon {
  margin: 0 0 0 20px;
}
.topPanelSeperator {
  background-color: #ACADB0;
  width: 100%;
  height: 1px;
}
.topPanelBottomText {
  margin-top: 10px;
  display: block;
}
.topPanelDataText {
  font-size: 1.7em;
}
.dataRangeSelect {
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  font-size: 15px;
  width: 130px;
  height: 30px;
  padding: 0 10px;
  background-color: rgba(135, 131, 215, 0.2);
  color: black;
  border-radius: 10px;
  cursor: pointer;
}

.dataRangeSelect option {
  color: inherit;
  background-color: rgba(135, 131, 215, 0.2);
}

.dataRangeSelect::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #34495e;
  transition: .25s all ease;
  pointer-events: none;
}

/*  */
.topPanelHeaderInline {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.upcomingEventWrap {
  margin: 10px 0;
  height: 60%;
}
.upcomingEventDate {
  border-left: 2px solid #EF5F63;
}
.upcomingEventTitle {

}

/* responsive */

@media screen and (max-width: 1024px) {
  .topPanelDataRangeBox {
    width: 100%;
  }
  .topPanelDataRangeBox h3{
    width: 30%;
  }
  .topPanelDataRangeBox .topPanelDataIcon {
    width: auto;
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .topPanelData {
    flex-direction: column;
  }
  .topPanelDataBox {
    width: 100%;
    align-items: center;
    flex-direction: row;
    padding: 5px 20px;
  }
  .topPanelDataSummary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
  }
  .topPanelDataSummary p {
    margin-right: 20px;
  }
  .topPanelDataIcon {
    width: 10%;
  }
  .topPanelSeperator {
    width: 0;
  }
  .topPanelDataBox div:last-child {
    width: 20%;
  }
  .upcomingEventWrap {
    height: 100%;
    width: 25%;
  }
  .topPanelHeaderInline {
    width: 30% !important;
  }
}